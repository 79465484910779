import { defineStore } from "pinia";
import { apiBackendAuthAxios } from "@/axiosAuth.js";
import { de } from "@faker-js/faker";

export const useUserCacheStore = defineStore("userCache", {
  state: () => {
    return {
      userCache: {},
    };
  },
  actions: {
    async fetchUsers(posts) {
      const usersToFetch = [];
      const users = [];
      posts.forEach(({ userId, userUpdatedAt }) => {
        if (typeof this.userCache[userId] === "undefined") {
          usersToFetch.push(userId);
          return;
        }

        if (
          new Date(this.userCache[userId].updatedAt) < new Date(userUpdatedAt)
        ) {
          usersToFetch.push(userId);
          return;
        }

        users.push(this.userCache[userId]);
      });

      const usersRequested = [...new Set(posts.map((p) => p.userId))].length;
      const cachedUsers = usersRequested - [...new Set(usersToFetch)].length;
      console.log(
        `Requested users: ${usersRequested}, fetched: ${
          [...new Set(usersToFetch)].length
        }, cached ${cachedUsers}`
      );

      if (usersToFetch.length === 0) {
        return users;
      }

      const { data } = await apiBackendAuthAxios.post("/profile", {
        ulids: [...new Set(usersToFetch)],
      });

      data[0].data.forEach((user) => {
        this.userCache[user.ulid] = user;
        users.push(user);
      });

      return users;
    },
  },
});
