import { defineStore } from "pinia";
import { apiBackendAuthAxios } from "@/axiosAuth.js";

export const useVideoCacheStore = defineStore("videoCache", {
  state: () => {
    return {
      videoCache: {},
    };
  },
  actions: {
    fetchVideo: async function (videoId) {
      return this.videoCache[videoId];
    },
    updateVideo: function (video) {
      this.videoCache[video.id] = video;
    },
    updateVideoDetails: function (data) {
      if (typeof this.videoCache[data.id] === "undefined") {
        return;
      }

      this.videoCache[data.id] = {
        ...this.videoCache[data.id],
        data,
      };
    },
    fetchVideos: async function (videoData) {
      const videosToFetch = [];
      const videos = [];

      videoData.forEach(({ ulid, updatedAt }) => {
        if (typeof this.videoCache[ulid] === "undefined") {
          videosToFetch.push(ulid);
          return;
        }

        if (new Date(this.videoCache[ulid].updatedAt) < new Date(updatedAt)) {
          videosToFetch.push(ulid);
          return;
        }

        videos.push(this.videoCache[ulid]);
      });

      const postsRequested = videoData.length;
      const cachedPosts = postsRequested - [...new Set(videosToFetch)].length;
      console.log(
        `Requested posts: ${postsRequested}, fetched: ${
          [...new Set(videosToFetch)].length
        }, cached ${cachedPosts}`
      );

      if (videosToFetch.length === 0) {
        return videos;
      }

      const {
        data: { data: mappedVideoData },
      } = await apiBackendAuthAxios.post(`/posts/map`, {
        ulids: [...new Set(videosToFetch)],
        responseType: "videos",
        showAll: 1,
      });

      mappedVideoData.forEach((video) => {
        this.videoCache[video.id] = video;
        videos.push(video);
      });

      return videos;
    },
  },
});
