import { transformToVideo } from "@/types/Video.tsx";
import { useUserCacheStore } from "@/stores/userCacheStore.tsx";
import { useVideoCacheStore } from "@/stores/videoCacheStore.tsx";
import { pinia } from "@/pinia.js";

const cacheStore = useUserCacheStore(pinia);
const videoStore = useVideoCacheStore(pinia);

export const fetchVideosFromCache = async (videos) => {
  if (videos.length === 0) {
    return [];
  }

  const videoData = await videoStore.fetchVideos(videos);
  if (videoData.length === 0) {
    return [];
  }

  const users = await cacheStore.fetchUsers(videos);

  return videos
    .map((v) => v.ulid)
    .filter((v1) => videoData.filter((v2) => v1 === v2.id).length > 0)
    .map((v1) => {
      return videoData.filter((v2) => v1 === v2.id)[0];
    })
    .map((v) => transformToVideo(v, users));
};
