<template>
  <div id="trending-videos">
    <router-link
      class="flex items-center justify-start m-4 pb-0 space-x-4 select-none"
      to="/trending-videos"
    >
      <span class="image-container">
        <img :src="TrendingIcon" alt="RecentlyAddedIcon" />
      </span>
      <p class="text-lg font-bold m-0 text-black dark:text-white">
        Trending videos
      </p>
    </router-link>
    <div
      class="video-grid"
      :style="`grid-template-columns: repeat(${columns}, 1fr)`"
      v-if="loading"
    >
      <VideoSkeleton v-for="n in maxVideos" :key="n" />
    </div>
    <div
      class="video-grid"
      :style="`grid-template-columns: repeat(${columns}, 1fr)`"
      v-else
    >
      <VideoPreview
        v-for="video in videosToShow"
        :key="video.id"
        :video="video"
      />
    </div>
    <div
      v-if="!loading && trendingVideos.length === 0"
      class="text-center h-full"
    >
      No videos
    </div>
  </div>
</template>

<script setup>
import {
  computed,
  ref,
  watch,
  onMounted,
  onBeforeUnmount,
  nextTick,
} from "vue";
import VideoPreview from "@/components/videoPreview/VideoPreview.vue";
import VideoSkeleton from "@/components/VideoSkeleton.vue";
import { apiBackendAuthV4Axios } from "@/axiosAuth.js";
import TrendingIcon from "@/assets/playtv/icons/trending.svg";
import { useSidebarStore } from "@/stores/sidebarStore";
import { fetchVideosFromCache } from "@/components/feed/fetchVideosFromCache.js";

const emits = defineEmits(["loadedVideos"]);
const props = defineProps({
  loading: {
    type: Boolean,
    required: true,
  },
});

const loading = computed(() => {
  return props.loading;
});

const trendingVideos = ref([]);
const sidebarStore = useSidebarStore();

const columns = ref(0);

const maxVideos = computed(() => {
  return columns.value * 2;
});

const videosToShow = computed(() => {
  return trendingVideos.value.slice(0, maxVideos.value).sort((a, b) => {
    return b.publicationDate.getTime() - a.publicationDate.getTime();
  });
});

// Observe changes in the grid width and recalculate columns
const handleResizeEventTrending = () => {
  let videoGrid = document.querySelector(".video-grid")?.clientWidth;
  // console.log("videoGrid " + videoGrid);

  if (typeof videoGrid === "undefined") {
    return;
  }

  if (videoGrid < 550) {
    columns.value = 1;
  } else if (videoGrid < 870) {
    columns.value = 2;
  } else if (videoGrid < 1160) {
    columns.value = 3;
  } else if (videoGrid < 1450) {
    columns.value = 4;
  } else if (videoGrid < 1740) {
    columns.value = 5;
  } else if (videoGrid < 2030) {
    columns.value = 6;
  } else if (videoGrid < 2320) {
    columns.value = 7;
  } else if (videoGrid < 2610) {
    columns.value = 8;
  } else if (videoGrid < 2710) {
    columns.value = 9;
  } else {
    columns.value = 10;
  }

  // console.log("columns " + columns.value);
};

onMounted(() => {
  window.addEventListener("resize", handleResizeEventTrending);
});

onBeforeUnmount(() => {
  window.removeEventListener("resize", handleResizeEventTrending);
});

watch(
  () => sidebarStore.isExpanded(),
  () => {
    nextTick(() => {
      handleResizeEventTrending();
    });
  }
);

const loadTrendingVideos = async () => {
  try {
    // Fetch the latest videos
    const {
      data: { data: videos },
    } = await apiBackendAuthV4Axios.post("/trending/videos/random");

    if (videos.length === 0) {
      return;
    }

    // Transform the video data using the user data
    trendingVideos.value = await fetchVideosFromCache(videos);
  } catch (e) {
    console.error(e);
  } finally {
    emits("loadedVideos");
  }
};

onMounted(() => {
  handleResizeEventTrending();
  loadTrendingVideos();
});
</script>

<style scoped lang="scss">
.video-grid {
  display: grid;
  gap: 1rem;
  width: 100%;
  overflow-x: auto;
  grid-template-rows: repeat(2, 1fr);
  grid-auto-flow: row;
}

.skeleton-grid {
  display: grid;
  grid-auto-rows: 1fr;
  gap: 1rem;
  width: 100%;
}

#trending-videos {
  width: 100%;
  overflow-x: hidden;
}
</style>
