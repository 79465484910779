import { defineStore } from "pinia";
import { useAuthStore } from "@/stores/auth.tsx";
import { apiBackendAuthAxios, apiBackendAxios } from "@/axiosAuth.js";

// @ts-ignore
export const useGlobalStore = defineStore("globalStore", {
  state: () => {
    return {
      isUpdatingAccessToken: false,
      isMobile: false,
      reactionTypes: [],
      badgeTypes: [],
      lastUpdated: null,
      isLoadingBadgeTypes: false,
      reactionTypesLastUpdated: null,
      isLoadingReactionTypes: false,
    };
  },
  actions: {
    setUpdatingAccessToken(value) {
      this.isUpdatingAccessToken = value;
    },
    setMobile(value) {
      this.isMobile = value;
    },
    async refreshTypes() {
      await this.fetchReactionTypes();
      await this.fetchBadgeTypes();
    },
    // Fetches all user settings
    async fetchBadgeTypes() {
      // If the badge types were fetched less than 24 hours ago, don't fetch them again
      if (
        this.isLoadingBadgeTypes ||
        (this.badgeTypes.length > 0 &&
          this.lastUpdated &&
          this.lastUpdated > Date.now() - 1000 * 60 * 60 * 24)
      ) {
        return this.badgeTypes;
      }

      try {
        this.isLoadingBadgeTypes = true;

        const response = await apiBackendAuthAxios.get("/settings/badges");

        this.badgeTypes = response.data.data; // Assuming the response body directly contains the badge types

        this.lastUpdated = Date.now();

        this.isLoadingBadgeTypes = false;
      } catch (error) {
        console.error("Failed to fetch badge types:", error);
      }
    },
    async fetchReactionTypes() {
      // If the reaction types were fetched less than 24 hours ago, don't fetch them again
      if (
        this.isLoadingReactionTypes ||
        (this.reactionTypes.length > 0 &&
          this.lastUpdated &&
          this.lastUpdated > Date.now() - 1000 * 60 * 60 * 24)
      ) {
        return this.reactionTypes;
      }

      try {
        this.isLoadingReactionTypes = true;
        const response = await apiBackendAuthAxios.get("/settings/reactions");
        this.reactionTypes = response.data.data; // Assuming the response body directly contains the badge types
        this.reactionTypesLastUpdated = Date.now();
        this.isLoadingReactionTypes = false;
      } catch (error) {
        console.error("Failed to fetch reaction types:", error);
      }
    },
    async setReactionTypes() {
      const authStore = useAuthStore();
      try {
        let response = null;

        if (!authStore.authenticated) {
          response = await apiBackendAxios.get("/v3/settings/reactions");
          this.reactionTypes = response.data.data;
        } else {
          response = await apiBackendAuthAxios.get("/settings/reactions");
          this.reactionTypes = response.data.data;
        }
      } catch (error) {
        console.error(error);
        console.log("Error setting reaction types");
      }
    },
  },
});
