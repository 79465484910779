<template>
  <div v-if="showModal" class="modal-overlay" @click.self="closeModal">
    <div
      class="modal-content bg-white dark:bg-zinc-800 max-w-[95vw] md:w-[755px]"
    >
      <div class="flex items-center justify-between">
        <p class="text-lg m-0">Share</p>
        <button @click="closeModal" class="justify-center items-center flex">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.2807 14.2194C15.3504 14.2891 15.4056 14.3718 15.4433 14.4629C15.4811 14.5539 15.5005 14.6515 15.5005 14.7501C15.5005 14.8486 15.4811 14.9462 15.4433 15.0372C15.4056 15.1283 15.3504 15.211 15.2807 15.2807C15.211 15.3504 15.1283 15.4056 15.0372 15.4433C14.9462 15.4811 14.8486 15.5005 14.7501 15.5005C14.6515 15.5005 14.5539 15.4811 14.4629 15.4433C14.3718 15.4056 14.2891 15.3504 14.2194 15.2807L8.00005 9.06036L1.78068 15.2807C1.63995 15.4214 1.44907 15.5005 1.25005 15.5005C1.05103 15.5005 0.860156 15.4214 0.719426 15.2807C0.578695 15.1399 0.499634 14.9491 0.499634 14.7501C0.499634 14.551 0.578695 14.3602 0.719426 14.2194L6.93974 8.00005L0.719426 1.78068C0.578695 1.63995 0.499634 1.44907 0.499634 1.25005C0.499634 1.05103 0.578695 0.860156 0.719426 0.719426C0.860156 0.578695 1.05103 0.499634 1.25005 0.499634C1.44907 0.499634 1.63995 0.578695 1.78068 0.719426L8.00005 6.93974L14.2194 0.719426C14.3602 0.578695 14.551 0.499634 14.7501 0.499634C14.9491 0.499634 15.1399 0.578695 15.2807 0.719426C15.4214 0.860156 15.5005 1.05103 15.5005 1.25005C15.5005 1.44907 15.4214 1.63995 15.2807 1.78068L9.06036 8.00005L15.2807 14.2194Z"
              fill="white"
            />
          </svg>
        </button>
      </div>
      <div class="modal-body flex items-center space-x-2 py-4">
        <!-- Left Arrow -->
        <button @click="scrollLeft" class="scroll-arrow md:hidden">
          <img :src="Cheveron" alt="Cheveron" class="w-10" />
        </button>

        <!-- Share Options -->
        <div
          class="share-options overflow-x-auto space-x-4"
          ref="shareOptionsRef"
        >
          <SocialMediaButton
            v-for="platform in platforms"
            :key="platform.name"
            :platform="platform"
            :shareLink="shareLink"
          />
        </div>

        <!-- Right Arrow -->
        <button @click="scrollRight" class="scroll-arrow md:hidden">
          <img :src="Cheveron" alt="Cheveron" class="rotate-180 w-10" />
        </button>
      </div>
      <div class="share-link gap-y-2">
        <input
          :value="shareLink"
          readonly
          @focus="selectText($event)"
          class="share-input bg-white dark:bg-zinc-800"
        />
        <button
          @click="copyLinkToClipboard"
          class="btn btn-primary flex items-center justify-center"
        >
          Copy
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";
import SocialMediaButton from "./SocialMediaButton.vue"; // Your social media button component
import Cheveron from "@/assets/playtv/icons/cheveron.svg";

const props = defineProps({
  video: {
    type: Object,
    required: false,
    default: null,
  },
  showModal: {
    type: Boolean,
    required: false,
    default: false,
  },
});
const emit = defineEmits(["close-modal"]);

const shareOptionsRef = ref(null); // Reference to the share-options container

// Generate share link based on the video id
const shareLink = computed(() => {
  return props.video
    ? `${window.location.origin}${
        props.video?.videoType === "BURST" ? "/b/" : "/v/"
      }${props.video?.id}`
    : "Loading...";
});

// Scroll left function using scrollLeft for direct control
const scrollLeft = () => {
  if (shareOptionsRef.value) {
    shareOptionsRef.value.scrollLeft = Math.max(
      shareOptionsRef.value.scrollLeft - 150,
      0 // Ensure it doesn't scroll past the leftmost point
    );
  }
};

// Scroll right function using scrollLeft for direct control
const scrollRight = () => {
  if (shareOptionsRef.value) {
    const maxScrollLeft =
      shareOptionsRef.value.scrollWidth - shareOptionsRef.value.clientWidth;
    shareOptionsRef.value.scrollLeft = Math.min(
      shareOptionsRef.value.scrollLeft + 150,
      maxScrollLeft // Ensure it doesn't scroll past the rightmost point
    );
  }
};

// Function to copy the link to clipboard
function copyLinkToClipboard() {
  navigator.clipboard
    .writeText(shareLink.value)
    .then(() => {
      alert("Link copied to clipboard!");
    })
    .catch((err) => {
      console.error("Failed to copy:", err);
    });
}

// Function to select input text on focus
function selectText(event) {
  event.target.select();
}

// Close modal
function closeModal() {
  emit("close-modal");
}

// List of share platforms
const platforms = [
  { name: "Parler", url: "https://app.parler.com?share-url=" },
  { name: "Twitter", url: "https://twitter.com/intent/tweet?text=" },
  { name: "Facebook", url: "http://www.facebook.com/sharer.php?u=" },
  { name: "Threads", url: "https://threads.net/intent/post?text=" },
  // { name: "Telegram", url: "https://app.telegram.com?share-url=" },
  {
    name: "LinkedIn",
    url: "https://www.linkedin.com/sharing/share-offsite/?url=",
  },
  // { name: "WhatsApp", url: "whatsapp://send?text=" },
  { name: "Email", url: "mailto:?body=" },
];
</script>
<style scoped lang="scss">
@import "../../assets/styles/mixins.scss";

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: var(--z-index-modal-overlay);
}

.modal-content {
  padding: 20px;
  border-radius: 8px;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.scroll-arrow {
  background-color: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  user-select: none;
}

.share-options {
  display: flex;
  justify-content: flex-start; /* Ensure items start from the left */
  align-items: center;
  gap: 1rem;
  padding: 0; /* Remove padding to ensure content aligns properly */
  margin: 0; /* Ensure no margin on container */
  overflow-x: auto; /* Enable horizontal scrolling */
  scroll-snap-type: x mandatory;
  -ms-overflow-style: none;
  scrollbar-width: none;

  @include breakpoint(medium) {
    gap: 0;
    width: 100%;
    justify-content: space-evenly;

    > div {
      padding: 0.25rem 0.05rem;
    }
  }
}

.share-options > * {
  scroll-snap-align: center;
}

.share-options::-webkit-scrollbar {
  display: none;
}

.share-link {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.share-input {
  flex: 1;
  padding: 8px;
  margin-right: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.copy-button {
  padding: 8px 16px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
}

.copy-button:hover {
  background-color: #0056b3;
}
</style>
