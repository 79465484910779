<template>
  <div class="video-skeleton py-2 md:p-1 video-preview">
    <!-- Thumbnail Skeleton -->
    <div
      class="w-full aspect-w-16 aspect-h-9 bg-zinc-300 dark:bg-zinc-700 md:rounded-lg animate-pulse"
    ></div>

    <!-- Video Information Skeleton -->
    <div class="flex items-start px-2 md:px-0 mt-2 space-x-1">
      <div
        class="size-8 shrink-0 bg-zinc-300 dark:bg-zinc-700 rounded-full animate-pulse"
      ></div>
      <div class="flex flex-col space-y-2 w-full">
        <div
          class="h-3 bg-zinc-300 dark:bg-zinc-700 rounded-md animate-pulse w-3/4"
        ></div>
        <div
          class="h-3 bg-zinc-300 dark:bg-zinc-700 rounded-md animate-pulse w-1/2"
        ></div>
        <div
          class="h-3 bg-zinc-300 dark:bg-zinc-700 rounded-md animate-pulse w-1/4"
        ></div>
      </div>
    </div>
  </div>
</template>
<script setup></script>
<style scoped>
.video-preview {
  font-size: 12px;
  padding-top: 0;
  margin-bottom: 0;
}
</style>
