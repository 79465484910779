<template>
  <div class="flex flex-col space-y-2 items-center p-2">
    <a
      :href="platform.url + encodeURIComponent(shareLink)"
      target="_blank"
      rel="noopener noreferrer"
      class="social-button border border-gray-300 dark:border-gray-700"
    >
      <img :src="currentIcon" :alt="platform.name" class="size-4" />
    </a>
    <p class="w-16 text-center">{{ platform.name }}</p>
  </div>
</template>

<script setup>
import ParlerIcon from "@/assets/playtv/icons/parler-icon.svg";
import FBIcon from "@/assets/playtv/icons/fb-icon.svg";
import XIcon from "@/assets/playtv/icons/x-icon.svg";
import WhatsAppIcon from "@/assets/playtv/icons/whatsapp-icon.svg";
import LinkedInIcon from "@/assets/playtv/icons/linkedin-icon.svg";
import TelegramIcon from "@/assets/playtv/icons/telegram-icon.svg";
import ThreadsIcon from "@/assets/playtv/icons/threads-icon.svg";
import EmailIcon from "@/assets/playtv/icons/email-icon.svg";
import { computed } from "vue";

const props = defineProps({ platform: Object, shareLink: String });

const currentIcon = computed(() => {
  switch (props.platform.name) {
    case "Parler":
      return ParlerIcon;
    case "Facebook":
      return FBIcon;
    case "Twitter":
      return XIcon;
    case "Threads":
      return ThreadsIcon;
    case "LinkedIn":
      return LinkedInIcon;
    case "WhatsApp":
      return WhatsAppIcon;
    case "Telegram":
      return TelegramIcon;
    case "Email":
      return EmailIcon;
    default:
      return EmailIcon;
  }
});
</script>

<style scoped>
.social-button {
  width: 40px;
  height: 40px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #f0f0f0;
}

.social-button img {
  width: 24px;
  height: 24px;
}
</style>
