<template>
  <div class="notifications">
    <router-link to="/notifications" class="notifications-indicator">
      <SvgHandler :icon="BellIcon" />
      <div v-if="notificationCount > 0" class="number" />
    </router-link>
  </div>
</template>
<script setup>
import BellIcon from "@/assets/playtv/icons/notification-bell.svg";
import SvgHandler from "@/components/svgHandler/SvgHandler.vue";
import { computed, onBeforeUnmount, onMounted } from "vue";
import { useAuthStore } from "@/stores/auth.tsx";
import { useUserStore } from "@/stores/userStore.tsx";

import Echo from "laravel-echo";
import { apiBackendAuthAxios } from "@/axiosAuth.js";
import Pusher from "pusher-js";

let echo = null;
const authStore = useAuthStore();
const userStore = useUserStore();

const notificationCount = computed(() => {
  return userStore.notificationCount;
});

const runProbeTest = async () => {
  try {
    await apiBackendAuthAxios.get("/test/probe");
    return true;
  } catch (error) {
    console.error("Probe test failed", error);
    return false;
  }
};

onMounted(async () => {
  window.Pusher = Pusher;

  await runProbeTest();

  echo = new Echo({
    broadcaster: "pusher",
    key: import.meta.env.VITE_PUSHER_APP_KEY,
    cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
    wsHost: import.meta.env.VITE_ECHO_HOST,
    wsPort: import.meta.env.VITE_ECHO_PORT,
    wssPort: import.meta.env.VITE_ECHO_PORT,
    forceTLS: true,
    encrypted: true,
    enableStats: false,
    enabledTransports: ["ws", "wss"],
    authEndpoint: import.meta.env.VITE_API_HOST + "/broadcasting/auth",
    auth: {
      headers: {
        Authorization: `Bearer ${authStore.accessToken}`,
        Accept: "application/json",
      },
    },
  });

  echo
    .private(`notifications.${userStore.userId}`)
    .listen("NotificationCount", ({ unseenCount }) => {
      console.log("unseenCount " + unseenCount);
      userStore.updateNotificationCount(unseenCount);
    });

  const {
    data: { seen_count_false: unseenCount },
  } = await apiBackendAuthAxios.get("/user/notifications/count");
  userStore.updateNotificationCount(unseenCount);
});

onBeforeUnmount(() => {
  if (echo === null) {
    return;
  }

  echo.leaveAllChannels();
  echo.disconnect();
});
</script>
<style lang="scss" scoped>
@import "../../assets/styles/mixins.scss";

.notifications {
  margin-left: 1rem;
  margin-right: 0.5rem;

  @include breakpoint(small) {
    margin-right: 1.25rem;
    padding-left: 1.25rem;
  }
}

.notifications-indicator {
  display: inline-block;
  margin-top: 4px;
  position: relative;
  color: var(--white);

  .number {
    position: absolute;
    top: 0;
    right: 0;
    height: 10px;
    width: 10px;
    background-color: var(--error);
    border-radius: 50%;
  }
}
</style>
