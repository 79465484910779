<template>
  <div
    v-if="video"
    class="py-2 md:p-1 video-preview min-w-70 xyz"
    :class="[
      playlistActive ? 'playlist-active' : '',
      { 'deleted-video': video.isDeleted },
    ]"
    :playlist-order="video.order || 0"
  >
    <div class="flex items-center" style="gap: 10px">
      <div v-if="playlist" style="color: var(--primary); width: 20px">
        <div v-if="playlistActive" class="playlist-index-active">
          <svg
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15 8.92865C15.0004 9.09842 14.9569 9.2654 14.8736 9.41336C14.7904 9.56132 14.6703 9.68523 14.525 9.77302L5.52 15.2818C5.36818 15.3747 5.19429 15.4255 5.0163 15.4288C4.83831 15.4321 4.66266 15.3878 4.5075 15.3005C4.35382 15.2146 4.2258 15.0893 4.1366 14.9375C4.04741 14.7857 4.00026 14.6128 4 14.4368V3.42052C4.00026 3.24445 4.04741 3.07163 4.1366 2.91982C4.2258 2.76801 4.35382 2.6427 4.5075 2.55677C4.66266 2.46949 4.83831 2.42521 5.0163 2.42851C5.19429 2.4318 5.36818 2.48256 5.52 2.57552L14.525 8.08427C14.6703 8.17207 14.7904 8.29597 14.8736 8.44394C14.9569 8.5919 15.0004 8.75888 15 8.92865Z"
              fill="white"
            />
          </svg>
        </div>
        <div v-else class="playlist-index">
          <div class="handle" v-if="playListEditMode">
            <svg
              width="20"
              height="10"
              viewBox="0 0 20 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 1.39325H19M1 8.39325H19"
                stroke="var(--primary)"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div v-else>
            {{ index }}
          </div>
        </div>
      </div>
      <!-- marker -->

      <div style="width: 100%" :class="playlist ? 'playlist-video' : 'video'">
        <!-- Video Thumbnail -->
        <SensitivityOverlay
          :isSensitive="video?.isSensitive"
          :isWebOnly="video?.isWebOnly"
          :videoId="video?.id"
        >
          <span class="hidden"></span>
          <OptionalRouterLink
            :disabled="showSensitiveContent"
            :to="{
              path: linkToVideo,
              query: playlist ? { playlist } : {},
            }"
          >
            <div class="thumbnail-container">
              <div
                class="w-full relative flex justify-center items-center md:rounded-lg overflow-hidden border border-zinc-200 dark:border-zinc-900"
                :class="
                  video?.videoType === 'VIDEO'
                    ? 'aspect-w-16 aspect-h-9'
                    : 'aspect-w-9 aspect-h-16'
                "
                v-if="video.thumbnailUrl"
              >
                <img
                  :src="video.thumbnailUrl"
                  v-show="imgLoaded"
                  alt="Video Thumbnail"
                  class="h-full object-cover cursor-pointer md:rounded-lg"
                  @load="imgLoaded = true"
                  onerror="this.src='/images/thumbnail-template-burst.png'"
                />
                <div
                  v-show="!imgLoaded"
                  class="absolute inset-0 flex items-center justify-center bg-zinc-300 dark:bg-zinc-800 animate-pulse"
                ></div>
                <div
                  v-if="video.videoDuration > 0 && video.videoType === 'VIDEO'"
                  class="flex items-center justify-center video-length-label"
                >
                  <p class="text-white text-xs bg-black/75">
                    {{ formattedDuration }}
                  </p>
                </div>
                <div
                  v-if="watchedPercentage > 0"
                  class="h-[1rem] bg-red-600 z-10 absolute top-[97.9%] left-0"
                  :style="{ width: watchedPercentage + '%' }"
                ></div>
              </div>
              <div
                v-else
                class="w-full flex items-center justify-center bg-gray-300 dark:bg-gray-800 rounded-lg"
                :class="
                  video?.videoType === 'VIDEO'
                    ? 'aspect-w-16 aspect-h-9'
                    : 'aspect-w-9 aspect-h-16'
                "
              >
                <p class="text-center inline-flex items-center justify-center">
                  Thumbnail not found
                </p>
              </div>
            </div>
          </OptionalRouterLink>
        </SensitivityOverlay>
        <!-- Video Information -->
        <div
          class="flex items-start mt-2 space-x-2 video-information relative px-2 md:p-0"
        >
          <router-link :to="channelUrl" class="size-7 shrink-0">
            <UserAvatar
              :avatar="video.authorProfilePicture"
              :id="video.authorId"
              small
              class="rounded-full"
            />
          </router-link>
          <div class="flex-grow overflow-hidden">
            <router-link
              :to="{
                path: linkToVideo,
                query: playlist ? { playlist } : {},
              }"
            >
              <div class="flex">
                <h3 class="video-title">
                  {{ video.isDeleted ? "Deleted Video" : video.title }}
                </h3>
              </div>
            </router-link>

            <router-link
              :to="
                playlist
                  ? { path: linkToVideo, query: playlist ? { playlist } : {} }
                  : channelUrl
              "
              v-if="video.videoType === 'VIDEO'"
            >
              <p
                class="line-clamp-1 my-0 font-normal text-ellipsis overflow-hidden mr-5"
              >
                {{ video.authorName }}
              </p>
            </router-link>
            <p
              class="timestamp-views line-clamp-1 my-0 font-normal flex space-x-[7px]"
            >
              <span
                v-if="video.videoType === 'VIDEO'"
                v-html="timeAgoMessage"
              ></span
              ><span v-if="video.videoType === 'VIDEO'">•</span
              ><span>{{ formattedViews }}</span>
            </p>
          </div>
          <CustomDropdown
            v-if="isOwnChannel"
            :options="dropdownDropdownOptions"
            placeholder=""
            @change="handleDropdownAction"
            mini="true"
          />

          <CustomDropdown
            v-if="ownPlaylist"
            :options="ownPlaylistDropdownOptions"
            placeholder=""
            @change="handleOwnPlaylistDropdownAction"
            mini="true"
            rightPosition="0px"
          />
        </div>
      </div>
    </div>
  </div>

  <VideoShareButton
    :video="video"
    :showModal="showModal"
    @close-modal="toggleShareModal"
  />
</template>

<script setup>
import { ref, computed } from "vue";
import { timeAgo } from "@/helpers/timeAgo";
import { useModal } from "vue-final-modal";
import ConfirmDeleteVideoModal from "@/components/videoPreview/ConfirmDeleteVideoModal.vue";
import UserAvatar from "@/components/UserAvatar.vue";
import { useVideoStore } from "@/stores/videoStore";
import { useUserStore } from "@/stores/userStore";
import CustomDropdown from "../customDropdown/CustomDropdown.vue";
import SensitivityOverlay from "../SensitivityOverlay.vue";
import { formatViews } from "@/helpers.js";
import OptionalRouterLink from "@/components/videoPreview/OptionalRouterLink.vue";
import VideoShareButton from "@/views/video/VideoShareButton.vue";

const props = defineProps({
  video: Object,
  isOwnChannel: Boolean,
  playlist: String,
  playlistActive: Boolean,
  ownPlaylist: Boolean,
  index: Number,
  playListEditMode: Boolean,
});

const formattedViews = computed(() => {
  return formatViews(props.video.views);
});

const videoStore = useVideoStore();
const showModal = ref(false);

// Internal state for toggling sensitive content visibility
const showSensitiveContent = computed(() => {
  return (
    props.video.isSensitive &&
    videoStore.showSensitiveContent[props.video.id] !== true
  );
});

const dropdownDropdownOptions = [
  {
    label: "Edit",
    value: "edit",
    href: `/${props.video.videoType === "VIDEO" ? "v" : "b"}/${
      props.video.id
    }/edit`,
  },
  { label: "Delete", value: "delete" },
];

const ownPlaylistDropdownOptions = [
  { label: "Remove", value: "remove" },
  { label: "Share", value: "share" },
];

const linkToVideo = computed(() => {
  return `/${props.video.videoType === "VIDEO" ? "v" : "b"}/${props.video.id}`;
});

const handleDropdownAction = (action) => {
  switch (action) {
    case "edit":
      // router.push(`/v/${props.video.id}/edit`);
      // toggleDropdown();
      break;
    case "delete":
      showDeleteVideoModal();
      break;
    default:
  }
};

const handleOwnPlaylistDropdownAction = (action) => {
  switch (action) {
    case "share":
      toggleShareModal();
      break;
    case "remove":
      removeFromPlaylist();
      break;
  }
};

const toggleShareModal = () => {
  showModal.value = !showModal.value;
};

const emit = defineEmits(["deleteVideo", "removeFromPlaylist"]);

const imgLoaded = ref(false);

const deleteVideo = () => {
  emit("deleteVideo", props.video.id);
};

const removeFromPlaylist = () => {
  emit("removeFromPlaylist", props.video.id);
};

const { open, close } = useModal({
  component: ConfirmDeleteVideoModal,

  attrs: {
    video: props.video,
    onClose() {
      close();
    },
    onDeleteVideo() {
      deleteVideo();
    },
  },
});

const showDeleteVideoModal = () => {
  toggleDropdown();
  open();
};

// const dropdownVisible = ref(false);

const userStore = useUserStore();

// Get the last watched time and calculate the percentage of the video watched
const lastWatchedTime = computed(() => {
  // Ensure userStore.userId exists and that the entry for userStore.userId is initialized
  if (
    videoStore.lastWatchedTime[userStore.userId] &&
    videoStore.lastWatchedTime[userStore.userId][props.video.id]
  ) {
    return Math.ceil(
      videoStore.lastWatchedTime[userStore.userId][props.video.id]
    );
  }
  // If not available, default to 0
  return 0;
});

const watchedPercentage = computed(() => {
  if (props.video.videoDuration > 0) {
    return (lastWatchedTime.value / props.video.videoDuration) * 100;
  }
  return 0;
});

const formattedDuration = computed(() => {
  // Subtract 1 second to match player display
  const duration = Math.max(0, props.video.videoDuration - 1);
  const totalMinutes = Math.floor(duration / 60);
  const seconds = duration % 60;

  if (totalMinutes >= 60) {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${hours}:${minutes < 10 ? "0" : ""}${minutes}:${
      seconds < 10 ? "0" : ""
    }${seconds}`;
  }

  return `${totalMinutes}:${seconds < 10 ? "0" : ""}${seconds}`;
});

let timeAgoMessage = ref(timeAgo(new Date(props.video.publicationDate)));

const dropdownVisible = ref(false);
const toggleDropdown = () => {
  dropdownVisible.value = !dropdownVisible.value;
};

const channelUrl = computed(() => {
  return `/c/${encodeURIComponent(props.video.username)}`;
});
</script>

<style scoped lang="scss">
h3 {
  margin-bottom: 0;
  color: var(--primary);
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
}

.video-preview {
  font-size: 12px;
  padding-top: 0;
  margin-bottom: 0;
  overflow: hidden;
}

.video-length-label {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 10;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;

  p {
    padding: 0.15em 0.5em;
    border-radius: 6px;
    margin-bottom: 0.5em;
    margin-right: 0.5em;
    font-size: 12px;
  }
}

.video-information {
  color: var(--dim-primary);

  a,
  p {
    color: inherit;
  }
}

svg path {
  fill: var(--primary);
}

.content-container.blur-content::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  border-radius: 0.5rem;
  /* For Safari */
  z-index: 5;
  /* Below the interstitial overlay */
}

.interstitial-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 101%;
  height: 101%;
  background: linear-gradient(to right, var(red), var(blue));
  border-radius: 0.5rem;
  backdrop-filter: blur(20px);
  /* Apply blur to the overlay */
  -webkit-backdrop-filter: blur(20px);
  /* For Safari */
  z-index: 10;
  /* Under the content */
  /* Adjust opacity as needed */
}

.interstitial-content {
  position: absolute;
  /* Positioned in relation to the viewport */
  top: 50%;
  /* Center vertically */
  left: 50%;
  /* Center horizontally */
  border-radius: 0.5rem;
  transform: translate(-50%, -50%);
  /* Adjust width to fit content */
  text-align: center;
  justify-content: center;
  /* Adjust font size as needed */
  z-index: 15;
  /* Above the overlay */
  color: white;
  /* Font color */
}

.interstitial-content button {
  margin: auto;
  padding: 2px 20px;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.4);
  color: white;
  cursor: pointer;
  transition: background 0.3s;
}

.show {
  background: linear-gradient(to right, var(red), var(blue));
}

.video-title {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  word-break: break-word;
}

.playlist-active {
  background-color: var(--content-separator);
  border-radius: 12px;
  margin: -6px;
  padding: 10px;
}

.video {
  width: 100%;
}

.playlist-video {
  display: flex;

  > div {
    &:nth-child(1) {
      width: 100px;
    }

    &:nth-child(2) {
      flex-grow: 1;
    }
  }

  .video-information {
    > a {
      display: none;
    }
  }

  .timestamp-views {
    display: none;
  }

  .thumbnail-container {
    width: 100px;
  }
}

.playlist-item {
  .playlist-index-active {
    position: relative;
    left: -5px;
  }
}

.handle {
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  left: -6px;
  position: relative;
}
</style>
<style lang="scss">
.video-grid {
  .playlist-video {
    .sensitive-overlay-button {
      // background-color: red;
      height: 1.5rem;
      width: 1.5rem;
      top: 0;
      right: 0.25em;
    }

    .sensitive-content-label {
      font-size: 10px;
      margin: 0;
      padding: 0;
      line-height: initial;
    }

    .sensitive-content-button {
      font-size: 10px;
      margin: 0;
      padding: 0;
      line-height: initial;
    }

    .video-length-label p {
      zoom: 0.75;
    }
  }
}
</style>
