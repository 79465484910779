<template>
  <div class="menu">
    <SideBarButton
      @linkClicked="hideSidebarOnMobile"
      label="Discover"
      :icon="discoverIcon"
      to="/"
    />

    <SideBarButton
      @linkClicked="hideSidebarOnMobile"
      label="Bursts"
      :icon="burstsIcon"
      to="/b"
    />

    <SideBarButton
      @linkClicked="hideSidebarOnMobile"
      label="Trending"
      :icon="trendingIcon"
      to="/trending-videos"
    />

    <SideBarButton
      @linkClicked="hideSidebarOnMobile"
      label="Recently Added"
      :icon="ClockCountdown"
      to="/recent-videos"
    />
  </div>

  <div class="menu flex-grow">
    <!-- <SideBarButton
        @linkClicked="hideSidebarOnMobile"
        label="About"
        :icon="aboutIcon"
        to="/about"
      /> -->

    <!-- <SideBarButton
        @linkClicked="hideSidebarOnMobile"
        label="Support"
        :icon="supportIcon"
        to="/team"
      /> -->
    <SideBarButton
      @linkClicked="hideSidebarOnMobile"
      label="Log In"
      :icon="loginIcon"
      :to="loginPath"
    />
  </div>

  <div class="theme-switch">
    <DarkModeSwitch />
  </div>

  <div class="menu footer text-xs">
    <span class="material-icons"></span>

    <span class="text">
      <span class="flex flex-col gap-2 m-4">
        <span class="flex flex-row gap-4">
          <a href="https://help.parler.com/hc/90853520" target="_blank">Help</a>
        </span>
        <span class="text-gray-400">Powered by Parler &copy; 2025</span>
        <span class="text-gray-400">Version: {{ appVersion }}</span>
      </span>
    </span>
  </div>
</template>
<script setup>
import { computed } from "vue";
import { useSidebarStore } from "@/stores/sidebarStore.tsx";
import SideBarButton from "./SideBarButton.vue";
import discoverIcon from "@/assets/playtv/icons/sidebar/discover-icon.svg";
import loginIcon from "@/assets/playtv/icons/sidebar/login-icon.svg";
import ClockCountdown from "@/assets/playtv/icons/ClockCountdown.svg";
import burstsIcon from "@/assets/playtv/icons/sidebar/bursts-icon.svg";
import trendingIcon from "@/assets/playtv/icons/sidebar/trending-icon.svg";
import { useRoute } from "vue-router";
import DarkModeSwitch from "@/components/sidebar/DarkModeSwitch.vue";

const sidebarStore = useSidebarStore();
const appVersion = import.meta.env.VITE_APP_VERSION;

const route = useRoute();

const loginPath = computed(() => {
  return `/login?redirect=${route.path}`;
});

const hideSidebarOnMobile = () => {
  const width = window.innerWidth;

  if (width < 1080) {
    sidebarStore.hide();
  }
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/mixins.scss";
@import "./css/sidebar.scss";

.dark-theme-toggle {
  padding: 0.5rem 1rem;
}
</style>
