import { defineStore } from "pinia";

export const useSearchHistoryStore = defineStore("searchHistory", {
  historyStoreLimit: 10,

  state: () => ({
    searchHistory: [],
  }),

  actions: {
    addSearchTerm(term) {
      // Avoid dups
      const existingIndex = this.searchHistory.findIndex(
        (item) => item.term === term
      );

      if (existingIndex !== -1) {
        // If it exists, remove it from its current position
        this.searchHistory.splice(existingIndex, 1);
      }

      this.searchHistory.unshift({
        term,
        timestamp: new Date().toISOString(),
      });

      // Limit store history
      this.searchHistory = this.searchHistory.slice(0, this.historyStoreLimit);
    },

    clearHistory() {
      this.searchHistory = [];
    },

    removeSearchTerm(term) {
      this.searchHistory = this.searchHistory.filter(
        (item) => item.term !== term
      );
    },
  },

  persist: true,
  share: {
    enable: true,
    initialize: true,
  },
});
