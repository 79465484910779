import axios from "axios";
import { useAuthStore } from "./stores/auth.tsx";
import { useUserStore } from "@/stores/userStore.tsx";
import "navigator.locks";

const appendAccessToken = async (config) => {
  const authStore = useAuthStore();

  if (authStore.accessToken) {
    config.headers.Authorization = `Bearer ${authStore.accessToken}`;
  }

  return config;
};

const apiBackendAuthAxios = axios.create({
  baseURL: import.meta.env.VITE_API_HOST + "/v3",
});

const apiBackendAuthV4Axios = axios.create({
  baseURL: import.meta.env.VITE_API_HOST + "/v4",
});
apiBackendAuthAxios.interceptors.request.use(appendAccessToken, (_error) => {
  return Promise.reject(_error);
});

apiBackendAuthV4Axios.interceptors.request.use(appendAccessToken, (_error) => {
  return Promise.reject(_error);
});

const apiBackendAxios = axios.create({
  baseURL: import.meta.env.VITE_API_HOST,
});

export const refreshToken = async () => {
  await navigator.locks.request("refresh_token_lock", async () => {
    const authStore = useAuthStore();
    const userStore = useUserStore();
    try {
      const isValid = await validateAccessToken(authStore.accessToken);
      if (isValid) {
        return;
      }

      const rs = await apiBackendAxios.post("/auth/refreshtoken", {
        refresh_token: authStore.refreshToken,
      });

      const { access_token: accessToken, refresh_token: refreshToken } =
        rs.data;

      authStore.logIn({
        accessToken,
        refreshToken,
      });
    } catch (e) {
      authStore.logout();
      userStore.logout();
    }
  });
};

apiBackendAuthAxios.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    const originalConfig = error.config;
    if (error.response && error.response.status === 401) {
      originalConfig._retry = true;

      const authStore = useAuthStore();
      const userStore = useUserStore();

      return navigator.locks.request("refresh_token_lock", async () => {
        try {
          const isValid = await validateAccessToken(authStore.accessToken);
          if (isValid) {
            originalConfig.headers.Authorization = `Bearer ${authStore.accessToken}`;
            return apiBackendAuthAxios(originalConfig);
          }

          const rs = await apiBackendAxios.post("/auth/refreshtoken", {
            refresh_token: authStore.refreshToken,
          });

          const { access_token: accessToken, refresh_token: refreshToken } =
            rs.data;

          authStore.logIn({
            accessToken,
            refreshToken,
          });

          originalConfig.headers.Authorization = `Bearer ${accessToken}`;
          return apiBackendAuthAxios(originalConfig);
        } catch (e) {
          authStore.logout();
          userStore.logout();
          return e;
        }
      });
    }

    return Promise.reject(error);
  }
);

apiBackendAuthV4Axios.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    const originalConfig = error.config;
    if (error.response && error.response.status === 401) {
      originalConfig._retry = true;

      const authStore = useAuthStore();
      const userStore = useUserStore();

      return navigator.locks.request("refresh_token_lock", async () => {
        try {
          const isValid = await validateAccessToken(authStore.accessToken);
          if (isValid) {
            originalConfig.headers.Authorization = `Bearer ${authStore.accessToken}`;
            return apiBackendAuthV4Axios(originalConfig);
          }

          const rs = await apiBackendAxios.post("/auth/refreshtoken", {
            refresh_token: authStore.refreshToken,
          });

          const { access_token: accessToken, refresh_token: refreshToken } =
            rs.data;

          authStore.logIn({
            accessToken,
            refreshToken,
          });

          originalConfig.headers.Authorization = `Bearer ${accessToken}`;
          return apiBackendAuthV4Axios(originalConfig);
        } catch (e) {
          authStore.logout();
          userStore.logout();
          return e;
        }
      });
    }

    return Promise.reject(error);
  }
);

const validateAccessToken = async (accessToken) => {
  try {
    await apiBackendAxios.get("/v3/user", {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return true;
  } catch (error) {
    return false;
  }
};

export { apiBackendAuthAxios, apiBackendAuthV4Axios, apiBackendAxios };
